<template>
	<div class="b-page-wrapper administrator">
		<div class="b-head-block b-head-block--link">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<router-link class="b-link b-link--header" to="/assignment" title="">
						<iconAngleleftSvg class="b-icon b-icon--header icon-angle-left" />
						<span>Назад</span>
					</router-link>
					<h1 class="b-title b-title--h1">
                        {{ isNewUser ? 'Добавление нового' : 'Редактирование' }} пользователя
					</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-window">
				<div class="b-form" action="/">
					<div class="b-form__first-lavel">
						<!-- <div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="role">Роль
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-select">
									<select :disabled="disabledInpRole" v-model="obj.roleId" class="b-select__select-field" id="role" name="role">
										<option class="b-select__option" :value="null">Выбрать роль</option>
										<option v-for="(item, ind) in listRoles" :key="ind" class="b-select__option" :value="item.id">{{item.name}}</option>
									</select>
									<div class="b-icon">
										<iconAngleDownSvg class="b-icon--down icon-angle-down" />
									</div>
								</div>
							</div>
						</div> -->

						<!-- <div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">Наименование МПНКО
								</label>
							</div>
							<div class="b-form__right">
								<div class="b-select">
									<v-select
										v-model="obj.companyId"
										:disabled="obj.roleId == 2"
										id="role" name="role"
										:options="listCompanies"
										:reduce="item => item.id"
									  label="name">
									</v-select>
									<div class="b-icon">
										<iconAngleDownSvg class="b-icon--down icon-angle-down" />
									</div>
								</div>
							</div>
						</div> -->

						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">Роль*</label>
							</div>
							<div class="b-form__right">
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										placeholder="Выберите роль"
										v-model="obj.roleId"
										:reduce="obj => obj.id"
										:options="listRoles"
										:loading="listRoles.length === 0"
										:disabled="listRoles.length === 1"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">Поиск не дал результатов <em>{{ search }}</em>.</template>
										</template>
									</v-select>
								</div>
							</div>
						</div>

                        <div v-if="authUser.role.id === 8" class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="name">Организация*</label>
							</div>
							<div class="b-form__right">
								<div class="b-select b-select--dual b-select--clinical b-select--type">
									<v-select
										placeholder="Выберите организацию"
										v-model="obj.companyId"
										:reduce="obj => obj.id"
										:options="listCompanies"
										:disabled="listCompanies.length === 1"
										label="name"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">Поиск не дал результатов <em>{{ search }}</em>.</template>
										</template>
									</v-select>
								</div>
							</div>
						</div>

						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="fio">ФИО пользователя*</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.name" class="b-input__input-field" id="fio" type="text" placeholder="Введите ФИО пользователя" name="fio"/>
									<i class="b-icon b-icon--check icon-check" />
                                    <i class="b-icon b-icon--close icon-close" />
								</div>
							</div>
						</div>

						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">Логин*</label>
							</div>
							<div class="b-form__right">
								<div class="b-input">
									<input v-model="obj.email" :class="{'border': flagLogin}" class="b-input__input-field" id="email-developer" type="email" placeholder="Введите e-mail пользователя" name="email-developer"/>
									<i class="b-icon b-icon--check icon-check" />
                                    <i class="b-icon b-icon--close icon-close" />
								</div>
							</div>
						</div>

                        <div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">Уполномочен подписывать документы*</label>
							</div>
							<div class="b-form__right">
                                <div class="v-item-group v-slide-group v-chip-group">
									<div class="v-slide-group__wrapper">
										<div class="v-slide-group__content">
											<span
                                                @click="enableSign(true)"
                                                :class="{
													'primary v-chip--active': obj.signEnabled,
													'v-chip--disabled': authUser.id == $route.params.id
												}"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
                                                <span class="v-chip__content">Да</span>
                                            </span>
                                            <span
                                                @click="enableSign(false)"
                                                :class="{
													'primary v-chip--active': !obj.signEnabled,
													'v-chip--disabled': authUser.id == $route.params.id
												}"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
                                                <span class="v-chip__content">Нет</span>
                                            </span>
										</div>
									</div>
								</div>
							</div>
						</div>

                        <div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">Загрузить ЭЦП</label>
							</div>
							<div class="b-form__right">
                                <p v-if="!obj.signEnabled">Для загрузки нужно подтвердить уполномоченного подписывать документ</p>
								<input v-else @change="uploadFileEvent($event)" type="file" accept=".cer">
							</div>
						</div>

						<div class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label">Открытая часть ЭЦП</label>
							</div>
							<div class="b-form__right">
								<a v-if="obj.signEnabled && haveFile" @click="downloadECP()" class="b-button b-button--empty b-button--noborder">
									Скачать
								</a>
								<p v-else>Нет</p>
							</div>
						</div>

                        <div v-if="!isNewUser" class="b-form__group b-form__group">
							<div class="b-form__left">
								<label class="b-label" for="email-developer">Деактивировать профиль</label>
							</div>
							<div class="b-form__right">
                                <div class="v-item-group v-slide-group v-chip-group">
									<div class="v-slide-group__wrapper">
										<div class="v-slide-group__content">
											<span
                                                @click="obj.isBlocked = true"
                                                :class="{ 'primary v-chip--active': obj.isBlocked }"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
                                                <span class="v-chip__content">Да</span>
                                            </span>
                                            <span
                                                @click="obj.isBlocked = false"
                                                :class="{ 'primary v-chip--active': !obj.isBlocked }"
                                                class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light"
                                            >
                                                <span class="v-chip__content">Нет</span>
                                            </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="b-form__group b-form__group--button">
						<button @click="save(obj)" :disabled="loading" :class="{'blur': loading }" class="b-button b-button--fill b-button--full position-relative">
                            {{ isNewUser ? 'Добавить пользователя' : 'Сохранить' }}

                            <ClipLoader v-if="loading" class="loading" color="#04092a" :size="30"/>
                        </button>

						<router-link class="b-button b-button--empty b-button--full" :to="loading ? '' : '/assignment'" title="Отменить">Отменить</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import iconAngleDownSvg from "@/assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "@/assets/icons/svg/angle-left.svg"
import { mapGetters, mapActions } from 'vuex'
import { ClipLoader } from '@saeris/vue-spinners'

export default {
    name: 'AssignNewUser',
	components: {
		iconAngleleftSvg,
		iconAngleDownSvg,
        ClipLoader
	},
    computed: {
		...mapGetters({
			apiUrl: 'apiUrl'
        }),
        authUser() {
            let user = JSON.parse(localStorage.getItem('userInfo'));

            return user.userInfo
        },
        isNewUser() {
            return this.$route.params.id === 'new'
        }
    },
	data: () => ({
		loading: false,
		flagLogin: false,
		disabledInpRole: false,
		obj: {
			roleId: null,
			companyId: null,
			email: '',
			name: '',
			isBlocked: false,
			signEnabled: false,
            file: null
        },
        haveFile: null,
		listRoles: [],
		listCompanies: [],
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		enableSign(enabled) {
			if (this.authUser.id === this.$route.params.id) {
				return;
			}

			this.obj.signEnabled = enabled;

			if (!this.obj.signEnabled) {
				this.obj.file = null;
			}
		},
        uploadFileEvent(event) {
            this.obj.file = event.target.files[0];
        },
        uploadFile(file, row) {
            if(!file) {
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке файла, обратитесь к Администратору!' })
                return;
            }

            let fData = new FormData();

            fData.append('file', file);

            this.apiAll({ type: 'post', url: `api/SignUser/${row.id}/AddSignature`, obj: fData })
                .then((res) => {
                    if(res.data.success) {
						this.haveFile = res.data.result;
                        this.close(this.isNewUser)
                        return;
                    }

                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке сертификата, обратитесь к Администратору!' })
                    this.loading = false;
                })
                .catch((error) => {
                    if(error) {
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Произошла ошибка при загрузке сертификата, обратитесь к Администратору!' })
                        this.loading = false;
                    }
                })
        },
		downloadECP() {
            this.api.get(`${this.apiUrl}api/SignUser/${this.$route.params.id}/DownloadSignature`, { responseType: 'blob' })
			.then((res) => {
                let blob = new Blob([res.data], {type: "application/octet-stream"}),
                    fileURL = URL.createObjectURL(blob),
                    link = document.createElement("a");

                link.download = 'Открытая часть ЭЦП.cer';
                link.href = fileURL;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успех', text: 'Файл успешно загружен!' })
			})
			.catch((error) => {
                console.log(error)
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Ошибка при выгрузке файла, обратитесь к Администратору!' })
            })
        },
		save(obj) {
			this.flagLogin = false
            if (obj.roleId === null || obj.name.length <= 3 || obj.email.length <= 3) {
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Заполните обязательные поля', text: 'Заполните все поля...' })
                return;
            }

            if (obj.signEnabled && !obj.file) {
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Предупреждение', text: 'Загрузите файл ЭЦП' })
                return;
            }

            this.loading = true;

			this.apiAll({ type: `${this.isNewUser ? 'post' : 'put'}`, url: `api/SignUser${this.isNewUser ? '' : '/'+this.$route.params.id}`, id: undefined, obj: {
				roleId: obj.roleId,
                email: obj.email,
                name: obj.name,
                isBlocked: obj.isBlocked,
                signEnabled: obj.signEnabled
            }})
            .then((res) => {
                if (res.data.success && res.status == 201 || res.status == 200) {
                    // После успешного создания пользователя, проверяем есть ли файл, если да, то прикрепляем его
                    if (obj.file) {
                        this.uploadFile(obj.file, res.data.result)
                    }
					else {
						this.close(this.isNewUser);
					}
                } else {
                    this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: res.data.userMessage })
						  this.flagLogin = true
                    this.loading = false;
                }
            })
            .catch((error) => {
                if(error) {
                    if(error.status == 409){
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Пользователь с данным email уже существует в Системе' })
                    } else {
                        this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка при создании', text: 'Заполните все поля...' })
                    }

                    this.loading = false;
                }
            })
		},
        close(isNewUser) {
			let message = isNewUser ? 'Пользователь успешно создан' : 'Изменения успешно сохранены!';
			this.$notify({ group: 'foo', duration: 5000, type: 'success', title: 'Успешно', text: message });

            this.obj = {
				companyId: null,
				roleId: null,
                email: '',
                name: '',
                isBlocked: false,
                signEnabled: false
            };

			this.haveFile = null;
            this.loading = false;
            this.$router.push('/assignment');
        },
        getUser(id) {
            this.apiAll({ type: 'get', url: `api/SignUser/${id}`})
                .then((res) => {
                    this.obj.roleId = res.data.roleId;
                    this.obj.email = res.data.login;
                    this.obj.name = res.data.name;
                    this.obj.signEnabled = res.data.signEnabled;
                    this.obj.isBlocked = res.data.isBlocked;

                    this.haveFile = res.data.signatureFileId;
                })
        },
		getRoles() {
			let action = null;

			if (this.authUser.role.id === 8) action = 'GetMpnko';
			else if (this.authUser.role.id === 10) action = 'GetMinzdravNps';
			else if (this.authUser.role.id === 11) action = 'GetCekkmp';

			this.apiAll({ type: 'get', url: `api/Roles/${action}`, id: undefined })
				.then(res => {
					this.listRoles = res.data;

					if (this.listRoles.length === 1) {
						this.obj.roleId = this.listRoles[0].id;
					}
				});
		}
	},
	created() {
		if (!this.isNewUser) {
            this.getUser(this.$route.params.id);
		}

		this.getRoles();

		if (this.authUser.role.id === 8) {
			this.listCompanies.push(this.authUser.company);
			this.obj.companyId = this.listCompanies[0].id;
		}
	}
}
</script>

<style scoped>
@import '../../../style/vuetify.min.css';

.position-relative {
    position: relative;
}

.blur {
    backdrop-filter: blur(15px);
}

.loading {
    position: absolute;
	left: 50%;
    top: 20%;
}
</style>

<style>
@import '../../../style/stylePages.css';

.v-chip-group .v-label {
	line-height: 1.75rem;
}

.v-chip {
	border-color: rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
	overflow: visible;
}

.v-chip:not(.v-chip--active) {
    background: #e0e0e0;
}
.border{
	border: 1px solid red;
}

.v-chip:not(.v-chip--outlined).primary {
	color: #fff;
}

.primary {
    background-color: #0061d9 !important;
    border-color: #0061d9 !important;
}

.b-select__select-field[disabled] {
    color: black!important;
	cursor: not-allowed;
}

.vs__dropdown-toggle {
	border-radius: 0;
	border: 1px solid #e5e5eb;
}

.vs__actions {
	display: none;
}
</style>
